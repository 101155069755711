html.webshop--ishomepage #home-row-1 {
  background: url(../../web/img/wallproducts-header.jpg) no-repeat top center #333;
  margin: 0;
  min-height: 470px;
}
html.webshop--ishomepage #home-row-1 #intro {
  margin-top: 100px;
}
html.webshop--ishomepage #home-row-1 #intro h1,
html.webshop--ishomepage #home-row-1 #intro p {
  color: #fff;
}
html.webshop--ishomepage #home-row-1 #intro p {
  font-size: 21px;
  margin-bottom: 20px;
  line-height: 1.4;
}
@media (max-width: 767px) {
  html.webshop--ishomepage #home-row-1 #intro {
    margin-top: 40px;
  }
  html.webshop--ishomepage #home-row-1 #intro h1 {
    font-size: 32px;
  }
}
html.webshop--ishomepage #home-row-1 #intro .button a {
  font-size: 18px;
  padding: 10px 16px;
}
html.webshop--ishomepage .twocol {
  padding: 10px 0;
}
html.webshop--ishomepage .twocol .row {
  align-items: center;
}
html.webshop--ishomepage .twocol .row .block.prods {
  margin-top: -130px;
}
@media (max-width: 767px) {
  html.webshop--ishomepage .twocol .row .block.prods {
    margin-top: 0;
  }
}
html.webshop--ishomepage #home-row-2 {
  padding: 10px 0;
}
html.webshop--ishomepage #home-row-3 {
  padding: 50px 0;
}
html.webshop--ishomepage .main--margintop {
  margin-top: 0;
  width: 100%;
}
html.webshop--ishomepage .productsgrid__header {
  display: none;
}
html.webshop--ishomepage main > * {
  max-width: 100%;
  margin: 0 auto;
  padding-right: 15px;
  padding-left: 15px;
}