@charset "UTF-8";
html.webshop--iscategorypage .block.prods {
  margin-top: 50px;
}

.pageimage {
  background: url(../../web/img/headers/assortiment-header.jpg) no-repeat top center #333;
  min-height: 264px;
  margin-bottom: 10px;
}

#home-row-1.category {
  background: url(../../web/img/headers/assortiment-header.jpg) no-repeat top center #333;
  min-height: 264px;
}
#home-row-1.category #intro {
  margin-top: 30px;
}
#home-row-1.category .cat-top-img {
  max-width: 100%;
}
@media (min-width: 1300px) {
  #home-row-1.category .cat-top-img {
    margin-top: 152px;
    margin-bottom: -45px;
  }
}
@media (min-width: 979px) and (max-width: 1300px) {
  #home-row-1.category .cat-top-img {
    margin-top: 152px;
    margin-bottom: -45px;
  }
}
@media (min-width: 768px) and (max-width: 992px) {
  #home-row-1.category .cat-top-img {
    margin-top: 183px;
    margin-bottom: -43px;
  }
}
@media (max-width: 767px) {
  #home-row-1.category .cat-top-img {
    margin-top: 151px;
    margin-bottom: -43px;
  }
}
@media (max-width: 480px) {
  #home-row-1.category .cat-top-img {
    margin-top: 151px;
    margin-bottom: -43px;
  }
}
@media (max-width: 480px) {
  #home-row-1.category {
    margin-bottom: 50px;
  }
}

.product_listing {
  margin: 40px 0;
  position: relative;
}
.product_listing .productsgrid__product--hidden {
  display: none;
}

.productsgrid_anchor {
  position: absolute;
  top: -100px;
  left: 0;
}

.page--categories .breadcrumb .widget-fullwidthimage img {
  border-radius: 0.25rem;
}

.product__related .productsgrid .productitem__baseprice > .price,
.combinesection__products .productsgrid .productitem__baseprice > .price {
  font-size: 16px;
}
.product__related .productsgrid .productitem__nowprice > span,
.combinesection__products .productsgrid .productitem__nowprice > span {
  font-size: 21px;
}

.card-columns .card {
  margin-bottom: 1.75rem;
}

.productsgrid a, .productsgrid a p, .productsgrid a:hover p,
.categorygrid a,
.categorygrid a p,
.categorygrid a:hover p {
  color: #58595b;
}
.productsgrid a:hover,
.categorygrid a:hover {
  text-decoration: none;
}

.categorygrid:empty {
  display: none;
}

.productsgrid__header {
  border-bottom: 2px solid #eee;
}
.productsgrid__header span {
  font-family: "futura-pt", sans-serif;
  font-weight: 700;
  margin-bottom: 10px;
  color: #26348b;
  text-transform: uppercase;
  line-height: 1.2;
  font-size: 1.25rem;
}

.productitem {
  font: inherit;
  cursor: pointer;
  transition: all 0.2s ease-in-out 0s;
  padding: 15px;
  border: none;
  border-bottom: 1px dotted #ddd;
}
.productitem .badge-danger {
  background-color: #d13539;
  position: absolute;
  max-width: 42px;
  left: 0;
  padding: 5px 6px;
  border-radius: 3px;
}
.productitem .btn {
  opacity: 1;
  transition: opacity 0.2s ease-in;
  -webkit-transition: opacity 0.2s ease-in;
}
.productitem:hover, .productitem:focus {
  background-color: #f3f3f3;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  text-decoration: none;
  color: inherit;
}
.productitem.ribbon:before {
  width: 124px;
  height: 124px;
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  pointer-events: none;
}
.productitem__image {
  display: flex;
  justify-content: center;
}
@media (max-width: 767px) {
  .productitem__image {
    justify-content: start;
  }
}
.productitem__image img {
  align-self: center;
  margin-bottom: 10px;
  max-height: 100px;
}
.productitem .card-body {
  padding: 0;
}
.productitem .card-body .productitem__description {
  font-size: 14px;
  line-height: 19px;
}
.productitem .card-body .productitem__description ul.checkmarks {
  margin-bottom: 0;
}
.productitem .card-body .productitem__description ul.checkmarks li:before {
  content: "●";
  font-size: 10px;
  line-height: 23px;
  top: -2px;
  color: #58595b;
  position: relative;
}
.productitem .card-body .productitem__description p {
  margin-bottom: 0;
}
.productitem__title {
  font-size: 17px;
  margin-bottom: 0;
}
.productitem .productitem__price {
  line-height: 21px;
}
@media (min-width: 320px) and (max-width: 576px) {
  .productitem .productitem__price {
    text-align: right;
    margin-top: 15px;
  }
}
.productitem__discountslogan {
  color: #00b900;
  font-weight: 600;
  font-size: 12px;
}
.productitem__baseprice > .price {
  display: inline-block;
  font-size: 14px;
  font-weight: 400;
  position: relative;
  color: #929292;
  margin-right: 5px;
}
.productitem__baseprice > .price:before {
  content: "";
  position: absolute;
  top: 11px;
  left: 0px;
  width: 100%;
  height: 9px;
  background: transparent;
  border-top: 1px solid #929292;
  border-radius: 0;
  transform: rotate(-11deg);
}
.productitem__nowprice {
  font-size: 12px;
}
.productitem__nowprice > span {
  font-size: 16px;
  font-weight: 600;
  color: #000;
  margin-top: 10px;
}
.productitem__baseprice > span.pricemultiple, .productitem__nowprice > span.pricemultiple {
  font-size: inherit;
  font-weight: inherit;
}
.productitem__message {
  color: #4aa814;
  font-weight: 600;
}

.productitem__mini a:hover {
  text-decoration: none;
}
.productitem__mini .productitem__mini__header {
  color: #000;
  min-height: 250px;
  background: #fff;
  padding: 20px 20px 0 20px;
  position: relative;
  z-index: 1;
  -moz-box-shadow: inset 0 0 7px 1px rgba(0, 0, 0, 0.4);
  -webkit-box-shadow: inset 0 0 7px 1px rgba(0, 0, 0, 0.4);
  box-shadow: inset 0 0 7px 1px rgba(0, 0, 0, 0.4);
}
.productitem__mini .productitem__mini__header .productitem__mini__title {
  clear: both;
  display: block;
}
.productitem__mini .productitem__mini__header .productitem__mini__title h3 {
  font-size: 19px;
  color: #23527c;
  margin: 0;
  line-height: 21px;
  word-break: break-word;
}
.productitem__mini .productitem__mini__header .productitem__mini__image {
  display: flex;
  justify-content: center;
}
.productitem__mini .productitem__mini__header .productitem__mini__image img {
  max-height: 180px;
  align-self: center;
}
.productitem__mini .productitem__mini__footer {
  background: #333;
  padding: 20px;
  margin-bottom: 20px;
  font-size: 14px;
  position: relative;
  color: #fff;
}
.productitem__mini .productitem__mini__footer .productitem__mini__prices {
  background: #be1522;
  padding: 5px 10px;
  position: absolute;
  z-index: 1;
  right: 20px;
  top: -55px;
  color: #fff;
  text-align: right;
  line-height: 16px;
  min-height: 65px;
}
.productitem__mini .productitem__mini__footer .productitem__mini__prices .skew.red:before {
  content: "";
  position: absolute;
  left: 0;
  top: -6px;
  overflow: visible;
  width: 100%;
  height: 25px;
  background: #be1522;
  z-index: -1;
  -webkit-transform: skewY(6deg);
  -moz-transform: skewY(6deg);
  -ms-transform: skewY(6deg);
  -o-transform: skewY(6deg);
  transform: skewY(6deg);
  -webkit-backface-visibility: hidden;
  backface-visibility: initial;
}
.productitem__mini .productitem__mini__footer .productitem__mini__prices .productitem__mini__price span {
  color: #fff;
  font-size: 22px;
}
.productitem__mini .productitem__mini__footer .productitem__mini__prices .productitem__mini__price .productitem__mini__baseprice,
.productitem__mini .productitem__mini__footer .productitem__mini__prices .productitem__mini__price .productitem__mini__nowprice {
  color: #fff;
  font-size: 12px;
  font-weight: normal;
  position: relative;
}
.productitem__mini .productitem__mini__footer .productitem__mini__prices .productitem__mini__price .productitem__mini__baseprice:before {
  position: absolute;
  content: "";
  left: 0;
  top: 50%;
  right: 0;
  border-top: 2px solid;
  border-color: inherit;
  -webkit-transform: rotate(-14deg);
  -moz-transform: rotate(-14deg);
  -ms-transform: rotate(-14deg);
  -o-transform: rotate(-14deg);
  transform: rotate(-14deg);
}

.categoryitem {
  font: inherit;
  cursor: pointer;
  transition: all 0.2s ease-in-out 0s;
  border: none;
  padding: 15px;
  background-color: #eee;
}
.categoryitem:hover, .categoryitem:focus {
  text-decoration: none;
  color: inherit;
}
.categoryitem .categoryitem__image {
  text-align: center;
}
.categoryitem .card-body {
  padding: 1rem;
}
.categoryitem .card-footer {
  position: relative;
  border-top: 0;
  background-color: rgba(0, 0, 0, 0.03);
}
.categoryitem__title {
  font-size: 24px;
  padding-bottom: 5px;
}
.categoryitem .card-body {
  font-size: 16px;
  color: #be1522;
}